<template>
  <div>
    <v-tour name="emotivaTour" :steps="steps" :options="tourOptions">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
          >
            <div slot="header" class="header__section">
              {{
                $t(
                  `appTour.steps.${
                    tour.steps[tour.currentStep].header.title
                  }.title`,
                  tour.steps[tour.currentStep].header.params
                )
              }}
            </div>
            <div slot="content" class="content__section">
              {{
                $t(
                  `appTour.steps.${
                    tour.steps[tour.currentStep].content
                  }.description`
                )
              }}
            </div>
            <div slot="actions" class="action__section">
              <div v-if="tour.currentStep === steps.length - 1">
                <b-button
                  class="btn-red text-uppercase tour__nav-button"
                  @click="onTourFinish(tour.stop)"
                >
                  {{ $t("button.gotIt") }}
                </b-button>
              </div>
              <div v-else>
                <b-button
                  class="btn-red text-uppercase tour__nav-button"
                  @click="tour.nextStep"
                >
                  {{ $t("button.next") }}
                </b-button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>

    <b-modal
      v-model="isModalOpen"
      size="lg"
      header-class="border-bottom-0 pb-0"
      hide-footer
      content-class="rounded-0"
      centered
      no-close-on-esc
      no-close-on-backdrop
      @close="onModalClose"
    >
      <template v-slot:default>
        <div class="text-center px-5" style="min-height: 240px">
          <h4 class="mb-4">
            {{ $t("appTour.welcomeModal.title") }}
          </h4>
          <p class="mb-5">{{ $t("appTour.welcomeModal.desciption") }}</p>
          <b-button
            class="text-uppercase"
            variant="red"
            @click="startTour"
            style="font-size: 14px"
          >
            {{ $t("appTour.welcomeModal.quickTutorial") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Utils from "./../Utils";
export default {
  watch: {
    isTourActive: {
      handler: function(curValue) {
        if (curValue) {
          this.$nextTick(() => {
            this.isModalOpen = true;
          });
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      isModalOpen: false,
      tourOptions: {
        useKeyboardNavigation: false,
        highlight: true
      }
    };
  },
  computed: {
    isTourActive() {
      return this.$store.getters["settings/isTourActive"];
    },
    isClientCustom() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    },
    steps() {
      const stepList = [
        {
          target: '[data-v-step="0"]',
          header: {
            title: "startHere"
          },
          content: `startHere`,
          params: {
            enableScrolling: false,
            placement: "bottom-end"
          }
        },
        {
          target: '[data-v-step="10"]',
          header: {
            title: "account"
          },
          content: "account",
          params: {
            enableScrolling: false,
            placement: "left-start"
          }
        },
        {
          target: '[data-v-step="11"]',
          header: {
            title: "projects"
          },
          content: "projects",
          params: {
            enableScrolling: false,
            placement: "left-start"
          }
        },
        {
          target: '[data-v-step="100"]',
          header: {
            title: "help",
            params: { index: this.isClientCustom ? 6 : 4 }
          },
          content: "help",
          params: {
            enableScrolling: false,
            placement: "left-start"
          }
        }
      ];

      if (this.isClientCustom) {
        const customUserSteps = [
          {
            target: '[data-v-step="12"]',
            header: {
              title: "billing"
            },
            content: "billing",
            params: {
              enableScrolling: false,
              placement: "left-start"
            }
          },
          {
            target: '[data-v-step="13"]',
            header: {
              title: "usage"
            },
            content: "usage",
            params: {
              enableScrolling: false,
              placement: "left-start"
            }
          }
        ];
        stepList.splice(stepList.length - 1, 0, ...customUserSteps);
      }

      return stepList;
    }
  },
  methods: {
    startTour() {
      this.isModalOpen = false;
      this.$nextTick(() => {
        this.$tours.emotivaTour.start();
      });
    },
    onModalClose(e) {
      if (e.trigger === "headerclose") {
        this.markTutorialAsSeen();
      }
    },
    onTourFinish(tourStopCallback) {
      tourStopCallback();
      this.markTutorialAsSeen();
    },
    markTutorialAsSeen() {
      this.$store.commit("settings/SET_TOUR_ACTIVE", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tour {
  /deep/ {
    .v-step {
      background-color: white;
      color: black;
      padding: 26px;
    }
    .header__section {
      text-align: start;
      color: white;
      margin: -26px -26px 0.5rem;
      padding: 16px;
      background-color: #454d5d;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .content__section {
      text-align: start;
      padding-top: 26px;
      padding-bottom: 26px;
    }
    .action__section {
      display: flex;
      justify-content: flex-start;
      .tour__nav-button {
        min-width: 120px;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss">
.v-tour--active {
  .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
    pointer-events: none;
    &.menu__item-container {
      margin: -8px;
      padding: 8px;
    }
  }
  .collapsed-menu {
    overflow: unset;
  }
}
</style>
